<template>
    <div>
        <div v-if="agendamentosDeposito.length && !loading">
            <div v-for="agendamentoDeposito in agendamentosDeposito" :key="agendamentoDeposito.id">
                <DepositoAntecipadoItem @reLoad="reLoad" :agendamentoDeposito="agendamentoDeposito"/>
            </div>
            <Paginator :rows="perPage" v-model:first="page" :totalRecords="total" @page="onPage($event)"
                template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Página {currentPage} de {totalPages}">
            </Paginator>
        </div>
        <AppLoadingWrapper v-if="loading" />
        <AppMensagemNotFound v-if="!agendamentosDeposito.length && !loading" :icon="'pi pi-dollar'"
            :textoNotFound="'Não foi encontrado nenhum pedido de reembolso.'" />
    </div>
</template>
<script>
import DepositoAntecipadoItem from '../deposito-antecipado-items/DepositoAntecipadoItem.vue';
import BaseService from '@/services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    components: { DepositoAntecipadoItem },
    data() {
        return {
            agendamentosDeposito: {},
            loading: false,
            perPage: 3,
            total: 0,
            page: 1,
            firstPage: 1,
            currentPerPage: null
        };
    },
    props: {
        pesquisa: {
            type: String
        },
        tipoFiltro: {
            type: String
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load(page = 1) {
            this.loading = true;
            try {
                const service = new BaseService('/agendamento-deposito/reembolso');
                const { data } = await service.findAll({
                    limit: this.perPage,
                    page: page ?? this.page,
                    filter: this.pesquisa,
                    filtrosExtras: { tipofiltro: this.tipoFiltro }
                });

                this.agendamentosDeposito = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
            } catch ({ response }) {
                const { data } = response;
                this.$toast.add({ severity: 'error', summary: 'Erro ao carregar reembolso!', detail: data.message, life: 3000 });
            }
            this.loading = false;
        },
        async onPage(event) {
            this.load(event.page + 1);
        },
        reLoad() {
            this.load();
        }
    }
};
</script>
