<template>
    <div class="card p-3 h-full">
        <div class="flex flex-row justify-content-between">
            <div class="text-800 mb-1">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="deposito-antecipado-form" />
                    <h3 class="ml-1">Depósito Antecipado <TourComponent tourName="deposito-antecipado"/></h3>
                </div>
            </div>
        </div>
        <div class="flex flex-row my-2">
            <div class="p-fluid mt-2 w-full md:mt-0 md:w-5 grid-nogutter">
                <InputText v-model="pesquisa" @keyup.enter="pesquisar" class="h-full step1" placeholder="Funcionário / Prestador / Cliente" />
            </div>
            <div class="p-fluid flex flex-row mt-5 w-full md:mt-0 md:w-5 grid-nogutter">
                <Dropdown
                    v-model="tipoFiltro"
                    :options="listarTipoFiltro"
                    optionLabel="name"
                    optionValue="name"
                    class="ml-2 w-14rem text-center align-items-center step2"
                    :class="{ 'p-invalid': filtroError }"
                    placeholder="Selecione o filtro"
                />
                <Button
                    icon="pi pi-sync"
                    :loading="loading"
                    class="p-button ml-2 w-13rem step3"
                    label="Remover filtros"
                    loadingIcon="pi pi-sync pi-spin"
                    @click="removerFiltro"
                    iconPos="right"
                />
                <Button
                    icon="pi pi-file-excel"
                    :loading="loadingExcelEmMassa"
                    class="p-button-success ml-2 w-13rem step4"
                    label="Impressão em Massa"
                    @click="gerarPdfImpressaoEmMassa"
                    loadingIcon="pi pi-sync pi-spin"
                    iconPos="right"
                />

                <Button
                    icon="pi pi-file-excel"
                    :loading="loadingExcelDeposito"
                    class="p-button-success ml-2 w-13rem"
                    label="Relatório Excel"
                    @click="gerarExcelRelatorio"
                    loadingIcon="pi pi-sync pi-spin"
                    iconPos="right"
                />
            </div>
        </div>
        <TabViewDepositoAntecipado ref="tabView" :pesquisa="pesquisa" :tipoFiltro="tipoFiltro" />
    </div>
</template>
<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import TabViewDepositoAntecipado from './components/TabViewDepositoAntecipado.vue';
import { getClientBase } from '@/services/http';
import TourComponent from '../../components/TourComponent.vue';

export default {
    components: { TabViewDepositoAntecipado, AppInfoManual, TourComponent },
    data() {
        return {
            pesquisa: null,
            loading: false,
            loadingExcelEmMassa: false,
            loadingExcelDeposito: false,
            tipoFiltro: null,
            listarTipoFiltro: [
                { name: 'Prestador', code: '1' },
                { name: 'Cliente', code: '2' },
                { name: 'Funcionário', code: '3' },
                { name: 'CNPJ', code: '3' }
            ],
            filtroError: false
        };
    },
    methods: {
        async gerarPdfImpressaoEmMassa() {
            this.loadingExcelEmMassa = true;
            try {
                const response = await getClientBase().get('deposito-antecipado/gerar-impressao-massa');
                const win = window.open(response.data.url, '_blank');
                win.focus();
                this.$toast.add({ severity: 'success', summary: 'Sucesso!', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'warn', summary: error.response?.data?.message || error.message, life: 3000 });
            }
            this.loadingExcelEmMassa = false;
        },
        async gerarExcelRelatorio() {
            this.loadingExcelDeposito = true;
            try {
                const response = await getClientBase().get('deposito-antecipado/gerar-relatorio-deposito-antecipado', { responseType: 'blob' });
                const file = window.URL.createObjectURL(new Blob([response.data]));
                const docUrl = document.createElement('a');
                docUrl.href = file;
                docUrl.setAttribute('download', 'relatorio_deposito.xlsx');
                document.body.appendChild(docUrl);
                docUrl.click();
                this.loadingExcelDeposito = false;
                this.$toast.add({ severity: 'success', summary: 'Sucesso!', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'warn', summary: error.response?.data?.message || error.message, life: 3000 });
            }
            this.loadingExcelDeposito = false;
        },

        pesquisar() {
            if (!this.tipoFiltro && this.pesquisa) {
                this.filtroError = true;
                return this.$toast.add({ severity: 'warn', summary: 'Por favor, informar o tipo de filtro', life: 3000 });
            }

            if (!this.pesquisa && this.tipoFiltro) {
                this.filtroError = true;
                return this.$toast.add({ severity: 'warn', summary: 'Filtro selecionado, informe a descrição do filtro', life: 3000 });
            }
            this.filtroError = false;
            this.$refs.tabView.load();
        },
        async removerFiltro() {
            this.loading = true;
            this.pesquisa = '';
            this.tipoFiltro = null;
            this.filtroError = false;
            setTimeout(() => {
                this.$refs.tabView.load();
            }, 100);
            this.loading = false;
        }
    }
};
</script>
