<template>
    <div class="flex flex-column h-full">
        <div class="h-full w-full flex flex-row  p-2">
            <div class="flex flex-column">
                <p class="text-lg font-bold m-0">Total de créditos </p>
                <div class="flex flex-row">
                    <p class="text-lg font-bold">R$: </p>
                    <p class="text-lg text-pink-500 ml-2" v-for="agendamento in agendamentoDepositoAgendamentosValor" :key="agendamento.id">
                        {{ agendamento.valorProcedimento }}
                        {{ agendamento == agendamentoDepositoAgendamentosValor[agendamentoDepositoAgendamentosValor.length -1 ] ? '' : ' +'  }}
                    </p>
                </div>
                <div class="flex flex-row">
                    <p class="text-2xl">Créditos: </p>
                    <p class="ml-2 text-2xl font-bold">{{ valorTotalDeCreditos }} </p>
                </div>
            </div>
        </div>
        <div class="h-4rem w-full flex flex-row gap-2 p-2">
            <Button :loading="loadingGerarCreditos" :disabled="loadingSolicitarReembolso" class="p-button-success p-button-outlined ml-auto" @click="gerarCreditos" label="Gerar Créditos" />
            <Button :loading="loadingSolicitarReembolso" :disabled="loadingGerarCreditos" class="p-button-success p-button-outlined" @click="solicitarReembolso" label="Solicitar Reembolso" />
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import { uniq } from 'ramda';

export default {
    emits: ['reLoad'],
    props: {
        agendamentoDeposito: {
            type: Object
        },
    },
    data() {
        return {
            loadingGerarCreditos: false,
            loadingSolicitarReembolso: false,
        }
    },
    computed: {
        valorTotalDeCreditos() {
            return this.$filters.formatCurrency(this.agendamentoDeposito.AgendamentoDepositoAgendamentos.reduce((a, b) => Number(a) + Number(b.valorProcedimento), 0));
        },
        agendamentoDepositoAgendamentosValor() {
            return this.agendamentoDeposito.AgendamentoDepositoAgendamentos.filter(AgendamentoDepositoAgendamentos => uniq(AgendamentoDepositoAgendamentos.agendamentos)).map((AgendamentoDepositoAgendamentos) => AgendamentoDepositoAgendamentos);
        },
    },
    methods: {
        async gerarCreditos() {
            this.loadingGerarCreditos = true;
            try {
                await getClientBase().post(`/agendamento-deposito/gerar-credito/${this.agendamentoDeposito.id}`);
                this.$emit('reLoad');
                this.$toast.add({ severity: 'success', summary: 'Crédito gerado com sucesso!', life: 3000 });
            } catch ({ response }) {
                const { data } = response
                this.$toast.add({ severity: 'error', summary: 'Erro ao gerar crédito!', detail: data.message, life: 3000 });
            }
            this.loadingGerarCreditos = false;
        },
        async solicitarReembolso() {
            this.loadingSolicitarReembolso = true;
            try {
                await getClientBase().post(`/agendamento-deposito/solicar-reembolso/${this.agendamentoDeposito.id}`)
                this.$emit('reLoad');
                this.$toast.add({ severity: 'success', summary: 'Reembolso solicitado com sucesso!', life: 3000 });
            } catch ({ response }) {
                const { data } = response
                this.$toast.add({ severity: 'error', summary: 'Erro ao solicitar reembolso!', detail: data.message, life: 3000 });
            }
            this.loadingSolicitarReembolso = false;
        },
    },
}
</script>
<style>

</style>