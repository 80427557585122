const EnumStatusAgendamentoDepositoAntecipado = {
    PENDENTE: 'PENDENTE',
    CONCLUIDO: 'CONCLUIDO',
    SOLICITADO_REEMBOLSO: 'SOLICITADO_REEMBOLSO',
    EM_ABERTO: 'EM_ABERTO',
    CREDITADO: 'CREDITADO',
    REEMBOLSADO: 'REEMBOLSADO',

}

export default EnumStatusAgendamentoDepositoAntecipado;