<template>
    <div>
        <Button label="Detalhes"  @click="visualizarDetalhes()" />
        <Dialog
            v-model:visible="openModalVisualizarDetalhes"
            header="Detalhes do depósito"
            :style="{ width: '50rem' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
            :position="position"
            :modal="true"
            :draggable="false"
        >
            <div v-for="agendamento in agendamentosDepositos" class="h-auto" :key="agendamento.id">
                <div class="">
                    <div class="{ 'text-pink-500': agendamento.idStatus == statusAgendamento.CANCELADO }">
                        <p><b>Funcionário:</b> {{ agendamento.funcionario.codigoSoc }} - {{ agendamento.funcionario.name }} <b>Cliente: </b> {{ agendamento.cliente.name }}</p>
                        <b>Procedimentos: </b> 
                        <div v-for="procedimento in agendamento?.procedimentos" class="h-auto" :key="procedimento.id">
                            {{ procedimento?.procedimentos?.name }} - {{ $filters.formatDate(procedimento?.dataAgendamento) }}
                        </div>
                        <Divider/>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>
<script>
import StatusAgendamento from '../../../../enums/StatusAgendamento';
export default {
    components: {},
    props: {
        agendamentosDepositos: {
            type: Object
        },
        agendamentoValores: {
            type: Object
        }
    },
    data() {
        return {
            openModalVisualizarDetalhes: false,
            statusAgendamento: StatusAgendamento
        };
    },
    computed: {},
    methods: {
        visualizarDetalhes() {
            this.openModalVisualizarDetalhes = true;
        },
        buscaDataAgendamento(agendamento) {
            const data = agendamento.procedimentos.map((procedimento) => procedimento.dataAgendamento);
            return data[0];
        }
    }
};
</script>
