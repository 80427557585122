<template>
    <div class="border-1 border-300 w-full" :class="{ 'h-13rem': agendamentoDeposito.prestador?.metodoPagamento == metodoPagamentoEnum.PIX, 'h-16rem': agendamentoDeposito.prestador?.metodoPagamento != metodoPagamentoEnum.PIX }">
        <div class="flex flex-row h-full">
            <div class="h-full p-0 col-7 overflow-auto">
                <DepositoAntecipadoSessao :agendamentoDeposito="agendamentoDeposito" />
            </div>
            <div class="h-full p-0 col-5">
                <DepositoAntecipadoSessaoButtonsReembolso  v-if="isReembolso" @reLoad="reLoad" :agendamentoDeposito="agendamentoDeposito" />
                <DepositoAntecipadoSessaoButtons v-else @reLoad="reLoad" :agendamentoDeposito="agendamentoDeposito" />
            </div>
        </div>
    </div>
</template>
<script>
import DepositoAntecipadoSessao from './DepositoAntecipadoSessao.vue';
import DepositoAntecipadoSessaoButtons from './DepositoAntecipadoSessaoButtons.vue';
import MetodoPagamentoEnum from '../../../../enums/MetodoPagamentoEnum';
import DepositoAntecipadoSessaoButtonsReembolso from './DepositoAntecipadoSessaoButtonsReembolso.vue';
import EnumStatusAgendamentoDepositoAntecipado from '../../../../enums/EnumStatusAgendamentoDepositoAntecipado';

export default {
    components: { DepositoAntecipadoSessaoButtons, DepositoAntecipadoSessao, DepositoAntecipadoSessaoButtonsReembolso },
    emits: ['reLoad'],
    props: {
        agendamentoDeposito: {
            type: Object
        },
    },
    data() {
        return {
            metodoPagamentoEnum: MetodoPagamentoEnum,
            enumStatus: EnumStatusAgendamentoDepositoAntecipado
        }
    },
    computed: {
        isReembolso() {
            if (this.agendamentoDeposito.status == this.enumStatus.SOLICITADO_REEMBOLSO) {
                return true;
            }
            return false;
        }
    },
    methods: {
        reLoad() {
            this.$emit('reLoad');
        }
    },
}
</script>
<style>

</style>