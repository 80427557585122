<template>
    <div class="flex flex-row h-full p-0">
        <div class="h-full col-8">
            <p class="text-lg font-bold">{{ titulo }}</p>
            <p class="text-lg font-semibold m-0">Razão Social: {{ agendamentoDeposito.prestador.tradeName }}</p>
            <p class="text-lg font-semibold m-0">CNPJ: {{ agendamentoDeposito.prestador.cnpj }}</p>
            <p class="text-lg font-semibold m-0">Método de pagamento: {{ agendamentoDeposito.prestador.metodoPagamento }}</p>
            <p v-if="agendamentoDeposito.prestador.metodoPagamento == metodoPagamentoEnum.PIX" class="text-lg font-semibold m-0">
                Chave PIX: {{ agendamentoDeposito.prestador.chavePix }}
            </p>
            <div v-if="agendamentoDeposito.prestador.metodoPagamento != metodoPagamentoEnum.PIX">
                <p class="text-lg font-semibold m-0">Titular conta: {{ agendamentoDeposito.prestador.nomeTitularConta }}</p>
                <div class="flex flex-row">
                    <div class="flex flex-column w-auto mr-3 col-4 p-0">
                        <p class="text-lg font-semibold overflow m-0">Banco: {{ agendamentoDeposito.prestador.nomeBanco }}</p>
                    </div>
                    <div class="flex flex-column w-auto col-4 p-0">
                        <p class="text-lg font-semibold overflow m-0">Agencia: {{ agendamentoDeposito.prestador.codigoAgencia }}</p>
                    </div>
                </div>
                <div class="flex flex-row">
                    <div class="flex flex-column w-auto mr-3 col-4 p-0">
                        <p class="text-lg font-semibold overflow m-0">Conta: {{ agendamentoDeposito.prestador.contaCorrente }}</p>
                    </div>
                    <div class="flex flex-column w-auto col-4 p-0">
                        <p class="text-lg font-semibold overflow m-0">Titular: {{ agendamentoDeposito.prestador.nomeTitularConta }}</p>
                    </div>
                </div>
            </div>
            <p
                class="text-lg font-semibold m-0"
                :class="{ 'text-pink-500': agendamentoDeposito.status != enumStatusAgendamentoDepositoAntecipado.SOLICITADO_REEMBOLSO }"
            >
                Crédito: R${{ $filters.formatCurrency(agendamentoDeposito.prestador?.prestadorSaldo?.saldo) ?? '00,00' }}
            </p>
        </div>
        <div v-if="agendamentoDeposito.status != enumStatusAgendamentoDepositoAntecipado.SOLICITADO_REEMBOLSO" class="col-4 h-full overflow-auto">
            <p class="text-lg font-bold">AGENDAMENTOS</p>
            <DepositoAntecipadoDetalhes :agendamentosDepositos="agendamentosUniq" :agendamentoValores = agendamentoDeposito />
        </div>
        <div v-else class="col-4 h-full overflow-auto">
            <p class="text-lg font-bold">AGENDAMENTOS</p>
            <div
                v-for="agendamentoDepositoAgendamento in agendamentoDepositoAgendamentosReembolso"
                class="h-auto"
                :key="agendamentoDepositoAgendamento.id"
            >
                <p class="text-pink-500">
                    {{ agendamentoDepositoAgendamento.agendamentos.funcionario.codigoSoc }} -
                    {{ agendamentoDepositoAgendamento.agendamentos.funcionario.name }}
                    {{ '/ R$' + agendamentoDepositoAgendamento.valorProcedimento }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import MetodoPagamentoEnum from '../../../../enums/MetodoPagamentoEnum';
import { uniq } from 'ramda';
import StatusAgendamento from '../../../../enums/StatusAgendamento';
import EnumStatusAgendamentoDepositoAntecipado from '../../../../enums/EnumStatusAgendamentoDepositoAntecipado';
import DepositoAntecipadoDetalhes from './DepositoAntecipadoDetalhes.vue'

export default {
    components: { DepositoAntecipadoDetalhes },
    props: {
        agendamentoDeposito: {
            type: Object
        },
    },
    computed: {
        agendamentosUniq() {
            return uniq(this.agendamentoDeposito.AgendamentoDepositoAgendamentos.map(ada => ada.agendamentos));
        },
        agendamentoDepositoAgendamentosReembolso() {
            return this.agendamentoDeposito.AgendamentoDepositoAgendamentos;
        },
        isEmAbertoOuPendente() {
            return this.agendamentoDeposito.status == EnumStatusAgendamentoDepositoAntecipado.EM_ABERTO || this.agendamentoDeposito.status == EnumStatusAgendamentoDepositoAntecipado.PENDENTE;
        },
        isConcluido() {
            return this.agendamentoDeposito.status == EnumStatusAgendamentoDepositoAntecipado.CONCLUIDO;
        },
        isCreditadoOuReembolso() {
            return this.agendamentoDeposito.status == EnumStatusAgendamentoDepositoAntecipado.CREDITADO || this.agendamentoDeposito.status == EnumStatusAgendamentoDepositoAntecipado.SOLICITADO_REEMBOLSO;
        },
        titulo() {
            if (this.isEmAbertoOuPendente) {
                return 'SOLICITAÇÃO DE PAGAMENTO IMEDIATO';
            }
            if (this.isConcluido) {
                return 'PAGAMENTO REALIZADO'
            }
            if (this.isCreditadoOuReembolso) {
                return 'REEMBOLSO - NÃO HOUVE ATENDIMENTO'
            }
            return 'SOLICITAÇÃO DE PAGAMENTO IMEDIATO';
        }
    },
    data() {
        return {
            metodoPagamentoEnum: MetodoPagamentoEnum,
            statusAgendamento: StatusAgendamento,
            enumStatusAgendamentoDepositoAntecipado: EnumStatusAgendamentoDepositoAntecipado
        }
    },
    methods: {}
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar-track {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
    width: 6px;
    height: 3px;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: #b7bec533;
    margin-left: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
    width: 6px;
    height: 3px;
}
</style>
