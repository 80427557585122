<template>
    <div>
        <TabView v-model:activeIndex="active">
            <TabPanel header="PENDENTE" class="step5">
                <TabPanelPendente ref="tabPanelPendente" :pesquisa="pesquisa" :tipoFiltro="tipoFiltro" v-if="active == 0" />
            </TabPanel>
            <TabPanel header="CONCLUÍDO" class="step6">
                <TabPanelConcluido ref="tabPanelConcluido" :pesquisa="pesquisa" :tipoFiltro="tipoFiltro" v-if="active == 1" />
            </TabPanel>
            <TabPanel header="REEMBOLSO" class="step7">
                <TabPanelReembolso ref="tabPanelReembolso" :pesquisa="pesquisa" :tipoFiltro="tipoFiltro" v-if="active == 2" />
            </TabPanel>
        </TabView>
    </div>
</template>
<script>
import TabPanelPendente from './tab-view-deposito-antecipado/TabPanelPendente.vue';
import TabPanelConcluido from './tab-view-deposito-antecipado/TabPanelConcluido.vue';
import TabPanelReembolso from './tab-view-deposito-antecipado/TabPanelReembolso.vue';

export default {
    components: { TabPanelPendente, TabPanelConcluido, TabPanelReembolso },
    data() {
        return {
            active: 0,
        };
    },
    props: {
        pesquisa: {
            type: String
        },
        tipoFiltro: {
            type: String
        }
    },
    methods: {
        async load() {
            switch (this.active) {
                case 0:
                    await this.$refs.tabPanelPendente.load();
                    break;
                case 1:
                    await this.$refs.tabPanelConcluido.load();
                    break;
                case 2:
                    await this.$refs.tabPanelReembolso.load();
                    break;
                default:
                    break;
            }
        }
    },
};
</script>
<style>

</style>
