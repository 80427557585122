<template>
    <div class="flex flex-row h-full">
        <div class="col-7 h-full">
            <Button v-if="!loadingAnexos && isEmAbertoOuPendente" :label="getLabelAnexo()" @click="anexar"
                :icon="getIconAnexo()" iconPos="right" class="p-button-lg p-button-outlined h-full w-full"
                :class="getColorButtonAnexo()"
                :disabled="isEmAberto || isCreditadoConcluidoOuReembolsado || valorTotalIsZero" />
            <Button v-if="!loadingAnexos && isCreditadoConcluidoOuReembolsado" :label="getLabelAnexo()"
                @click="visualizarAnexo" :icon="getIconAnexo()" iconPos="right"
                class="p-button-lg p-button-outlined h-full w-full" :class="getColorButtonAnexo()"
                :disabled="isCreditadoConcluidoOuReembolsado && !this.hasAnexo" />
            <Skeleton v-if="loadingAnexos" class="h-full w-full" />
        </div>
        <div v-if="isEmAbertoOuPendente" class="col-1 h-full flex align-items-end p-0 pb-3">
            <Button @click="voltarParaEmAberto" :disabled="isPendente || loadingConcluindo"
                class="p-button-danger w-full h-3rem" icon="pi pi-times" />
        </div>
        <div class="h-full" :class="{ 'col-4': isEmAbertoOuPendente, 'col-5': isCreditadoConcluidoOuReembolsado }">
            <div class="flex flex-column h-full">
                <div class="flex flex-column h-full justify-content-center align-items-center">
                    <div class="text-lg font-semibold">Total a pagar:</div>
                    <div class="text-sm text-pink-600 font-bold"
                        v-if="agendamentoDepositoClone?.prestador?.prestadorSaldo?.saldo && isEmAbertoOuPendente">
                        {{
                            $filters.formatCurrency(valorTotalProcedimentos) +
                            ' - ' +
                            $filters.formatCurrency(agendamentoDepositoClone?.prestador?.prestadorSaldo?.saldo)
                        }}
                    </div>
                    <div class="text-3xl font-bold">{{ $filters.formatCurrency(valorTotal) }}</div>
                </div>
                <div v-if="isEmAbertoOuPendente" class="flex align-items-end">
                    <Button v-if="!isEmAberto" class="p-button-success w-full" @click="concluir" label="Concluir"
                        :loading="loadingConcluindo" :disabled="loadingConcluindo" />
                    <Button v-if="isEmAberto && !isAtualizar" class="p-button w-full" @click="iniciar" label="Iniciar" />
                    <Button v-if="isAtualizar" class="p-button-warning w-full" @click="atualizar"
                        label="Atuallizar os valores" :loading="loadingAtualizando" :disabled="loadingAtualizando" />
                </div>
                <div v-else>
                    <Button v-if="isCreditadoConcluidoOuReembolsado" class="p-button p-button-outlined w-full"
                        @click="confirmar" :label="getLabelConfirmar()" :class="getColorConfirmar()"
                        :icon="getIconConfirmar()" iconPos="right" :disabled="isConfirmado" :loading="loadingConfirmar" />
                </div>
            </div>
        </div>
        <Dialog v-model:visible="openModalError" modal :closable="false" header="Erro ao atualizar os valores"
            :style="{ width: '30vw' }">
            <p>
                Ocorreu um erro ao atualizar os valores dos procedimentos, fazer manual a operação.
            </p>
            <div class="flex flex-row mb-2">
                <Checkbox v-model="concordoComErroCheck" :binary="true" :class="{
                    'p-invalid': this.isCloseConcordoComErroCheckErro
                }" />
                <p class="ml-2">Concordo que fui informado.</p>
            </div>
            <small class="p-error" v-if="this.isCloseConcordoComErroCheckErro">É obrigatório confirmar</small>
            <template #footer>
                <Button label="Fechar" class="p-button-outlined p-button-danger" icon="pi pi-times"
                    @click="fecharModalError()" text />
            </template>
        </Dialog>
        <AppAnexoDialog v-if="showAnexoDialog" :visible="showAnexoDialog" title="Comprovante do depósito" tipo="COMPROVANTE"
            origem="GESTAO_DEPOSITO_ANTECIPADO" :origemId="agendamentoDepositoClone.id" :singleUpload="true"
            @onClickCloseAnexo="onCloseAnexo" :modal="false" @onUpload="onUploadAnexo" @onDelete="onDeleteAnexo">
        </AppAnexoDialog>
        <AppAnexoDialog v-if="showAnexoVisualizacaoDialog" :visible="showAnexoVisualizacaoDialog"
            title="Visualização do comprovante do depósito" tipo="COMPROVANTE" :enableImport="false" :enableRemove="false"
            origem="GESTAO_DEPOSITO_ANTECIPADO" :origemId="agendamentoDepositoClone.id" :singleUpload="true"
            @onClickCloseAnexo="showAnexoVisualizacaoDialog = false" :modal="false">
        </AppAnexoDialog>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import EnumStatusAgendamentoDepositoAntecipado from '../../../../enums/EnumStatusAgendamentoDepositoAntecipado';

export default {
    emits: ['reLoad'],
    props: {
        agendamentoDeposito: {
            type: Object
        }
    },
    data() {
        return {
            isCloseConcordoComErroCheckErro: false,
            concordoComErroCheck: false,
            openModalError: false,
            loadingAtualizando: false,
            loadingConcluindo: false,
            showAnexoDialog: false,
            showAnexoVisualizacaoDialog: false,
            loadingAnexos: false,
            loadingConfirmar: false,
            enumStatusAgendamentoDepositoAntecipado: EnumStatusAgendamentoDepositoAntecipado
        };
    },
    computed: {
        agendamentoDepositoClone() {
            return this.agendamentoDeposito;
        },
        valorTotal() {
            if (this.isConcluido) {
                return Number(this.agendamentoDepositoClone.valorComDesconto);
            }
            if (this.agendamentoDepositoClone?.prestador?.prestadorSaldo?.saldo && this.isEmAbertoOuPendente) {
                return this.valorCalculoTotal;
            }
            return this.valorTotalProcedimentos;
        },
        valorTotalProcedimentos() {
            return Number(this.agendamentoDepositoClone.valor);
        },
        valorCalculoTotal() {
            const credito = Number(this.agendamentoDepositoClone?.prestador?.prestadorSaldo?.saldo);
            const calculo = this.valorTotalProcedimentos - credito;
            return calculo > 0 ? calculo : 0;
        },
        isPendente() {
            return this.agendamentoDepositoClone.status != this.enumStatusAgendamentoDepositoAntecipado.PENDENTE;
        },
        isCreditadoConcluidoOuReembolsado() {
            return (
                this.agendamentoDepositoClone.status == this.enumStatusAgendamentoDepositoAntecipado.CONCLUIDO ||
                this.agendamentoDepositoClone.status == this.enumStatusAgendamentoDepositoAntecipado.CREDITADO ||
                this.agendamentoDepositoClone.status == this.enumStatusAgendamentoDepositoAntecipado.REEMBOLSADO
            );
        },
        isEmAbertoOuPendente() {
            return (
                this.agendamentoDepositoClone.status == this.enumStatusAgendamentoDepositoAntecipado.EM_ABERTO ||
                this.agendamentoDepositoClone.status == this.enumStatusAgendamentoDepositoAntecipado.PENDENTE
            );
        },
        isEmAberto() {
            return this.agendamentoDepositoClone.status == this.enumStatusAgendamentoDepositoAntecipado.EM_ABERTO;
        },
        isAtualizar() {
            return this.agendamentoDepositoClone.valoresAtualizados === false;
        },
        isCreditado() {
            return this.agendamentoDepositoClone.status == this.enumStatusAgendamentoDepositoAntecipado.CREDITADO;
        },
        isReembolsado() {
            return this.agendamentoDepositoClone.status == this.enumStatusAgendamentoDepositoAntecipado.REEMBOLSADO;
        },
        isConcluido() {
            return this.agendamentoDepositoClone.status == this.enumStatusAgendamentoDepositoAntecipado.CONCLUIDO;
        },
        isConcluidoSemAnexo() {
            return this.isConcluido && !this.hasAnexo;
        },
        valorTotalIsZero() {
            return this.valorTotal <= 0;
        },
        hasAnexo() {
            return this.agendamentoDepositoClone.anexoId;
        },
        isConfirmado() {
            return this.agendamentoDepositoClone.confirmado;
        }
    },
    methods: {
        async concluir() {
            this.loadingConcluindo = true;
            this.loading = true;
            let hasError = false;
            if (!this.valorTotalIsZero) {
                hasError = await this.validaQuantidadeDeAnexos();
            }
            if (hasError) return;
            try {
                await getClientBase().post(`/agendamento-deposito/gestao-deposito`, {
                    agendamentosDepositoId: this.agendamentoDepositoClone.id,
                    agendamentosIds: this.agendamentoDepositoClone.AgendamentoDepositoAgendamentos.map((adas) => adas.agendamentoId),
                    valorComDesconto: this.valorTotal
                });
                this.$emit('reLoad');
                this.$toast.add({
                    severity: 'success',
                    summary: 'Depósito confirmado com sucesso!',
                    detail: 'para mais detalhes acesse a aba "Conluído"',
                    life: 3000
                });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Erro ao concluir depósito!', detail: err.message, life: 3000 });
            }
            this.loadingConcluindo = false;
            this.loading = false;
        },
        async atualizar() {
            this.loadingAtualizando = true;
            this.loading = true;
            try {
                await getClientBase().post(`/agendamento-deposito/buscar-procedimentos-valores`, {
                    prestadorId: this.agendamentoDeposito.prestadorId,
                    agendamentoDepositoId: this.agendamentoDepositoClone.id
                });
                this.$emit('reLoad');
                this.$toast.add({ severity: 'success', summary: 'Os valores dos procedimentos foram atualizados com sucesso!', life: 3000 });
            } catch (error) {
                this.openModalError = true;
                this.$toast.add({ severity: 'error', summary: 'Falha ao tentar atualizar os valores dos procedimentos', life: 3000 });
            } finally {
                this.loadingAtualizando = false;
            }
            return;
        },
        fecharModalError() {
            if (!this.concordoComErroCheck) {
                this.isCloseConcordoComErroCheckErro = true;
                return;
            }
            this.openModalError = false;
            this.$emit('reLoad');
        },
        async iniciar() {
            this.loading = true;
            try {
                await getClientBase().put(`/agendamento-deposito/iniciar`, {
                    agendamentosDepositoId: this.agendamentoDepositoClone.id,
                    valorComDesconto: this.valorTotal
                });
                this.$emit('reLoad');
                this.$toast.add({ severity: 'success', summary: 'Depósito iniciado com sucesso!', life: 3000 });
            } catch ({ response }) {
                const { data } = response;
                this.$toast.add({ severity: 'error', summary: 'Erro ao iniciar depósito!', detail: data.message, life: 3000 });
            }
            this.loading = false;
        },
        async voltarParaEmAberto() {
            this.loading = true;
            if (this.hasAnexo) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops, algo aconteceu!',
                    detail: 'Você tem arquivos anexados, exclua-os para poder continuar!',
                    life: 3000
                });
                this.loading = false;
                this.loadingConcluindo = false;
                return;
            }
            try {
                await getClientBase().put(`/agendamento-deposito/voltar-para-em-aberto`, { id: this.agendamentoDepositoClone.id });
                this.$emit('reLoad');
            } catch ({ response }) {
                const { data } = response;
                this.$toast.add({ severity: 'error', summary: 'Ops, algo aconteceu!', detail: data.message, life: 3000 });
            }
            this.loading = false;
        },
        async validaQuantidadeDeAnexos() {
            if (!this.hasAnexo) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops, algo aconteceu!',
                    detail: 'Você não pode concluir o depósito sem fazer o upload de um Anexo!',
                    life: 3000
                });
                this.loading = false;
                this.loadingConcluindo = false;
                return true;
            }
            return false;
        },
        async anexar() {
            this.showAnexoDialog = true;
        },
        async visualizarAnexo() {
            this.showAnexoVisualizacaoDialog = true;
        },
        async onCloseAnexo() {
            this.showAnexoDialog = false;
        },
        async onUploadAnexo(anexo) {
            this.agendamentoDepositoClone.anexoId = anexo.id;
            try {
                await getClientBase().put(`/agendamento-deposito`, { id: this.agendamentoDepositoClone.id, anexoId: anexo.id });
            } catch ({ response }) {
                const { data } = response;
                this.$toast.add({ severity: 'error', summary: 'Erro ao iniciar depósito!', detail: data.message, life: 3000 });
            }
        },
        async onDeleteAnexo() {
            this.agendamentoDepositoClone.anexoId = null;
            try {
                await getClientBase().put(`/agendamento-deposito`, { id: this.agendamentoDepositoClone.id, anexoId: null });
            } catch ({ response }) {
                const { data } = response;
                this.$toast.add({ severity: 'error', summary: 'Erro ao iniciar depósito!', detail: data.message, life: 3000 });
            }
        },
        async confirmar() {
            this.loadingConfirmar = true;
            try {
                await getClientBase().put(`/agendamento-deposito/confirmado/${this.agendamentoDepositoClone.id}`);
                this.agendamentoDepositoClone.confirmado = true;
                this.$toast.add({ severity: 'success', summary: 'Depósito confirmado com sucesso!', life: 3000 });
            } catch ({ response }) {
                const { data } = response;
                this.$toast.add({ severity: 'error', summary: 'Erro ao confirmar depósito!', detail: data.message, life: 3000 });
            }
            this.loadingConfirmar = false;
        },
        getIconAnexo() {
            if (this.hasAnexo) {
                return 'pi pi-thumbs-up';
            } else {
                return 'pi pi-cloud-upload';
            }
        },
        getColorButtonAnexo() {
            if (this.isCreditado) {
                return 'p-button-success';
            } else if (this.valorTotalIsZero || this.isReembolsado) {
                return 'p-button-warning';
            } else if (this.isConcluidoSemAnexo) {
                return 'p-button-warning';
            } else if (!this.hasAnexo) {
                return 'p-button-danger';
            } else if (this.hasAnexo) {
                return 'p-button-success';
            }
        },
        getLabelAnexo() {
            if (this.isCreditado) {
                return 'Crédito Gerado';
            } else if (this.isReembolsado) {
                return 'Solicitado Reembolso';
            } else if (this.valorTotalIsZero) {
                return 'Não é necessário o Anexo';
            } else if (this.hasAnexo) {
                return 'Anexado!';
            } else if (this.isConcluidoSemAnexo) {
                return 'Não é necessário o Anexo';
            } else if (!this.hasAnexo) {
                return 'Anexo do depósito';
            }
        },
        getLabelConfirmar() {
            if (this.isConfirmado) {
                return 'Confirmado!';
            }
            return 'Confirmar';
        },
        getColorConfirmar() {
            if (this.isConfirmado) {
                return 'p-button-success';
            }
            return 'p-button-primary';
        },
        getIconConfirmar() {
            if (this.isConfirmado) {
                return 'pi pi-thumbs-up';
            }
            return '';
        }
    }
};
</script>
